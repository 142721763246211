import {
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import React, { useState, FormEvent, useEffect } from 'react'
import styled from 'styled-components'
import CTAButton from './CTAButton'
import LoadingDots from './LoadingDots'
import { useSnackbar } from 'notistack'
import server from '../api/server'
import { ApiRequest } from '../api/ApiTypes'

const Container = styled.div`
  margin: 40px 0;
`

const InlineRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const PoaCard = styled(Card)`
  padding: 16px 24px;
  background-color: rgb(255, 255, 255, 0.95);
  width: 100%;
  max-width: 600px; // Prevent excessive width
  max-height: 90vh; // Prevents overflow
  overflow-y: auto; // Enables scrolling if needed
`
interface Payload {
  vin: string
  email: string
  customerType: string
  customerCountry: string
  customerLanguage: string
  templateList: { name: string; selected: boolean }[]
}
interface Response {
  message: string
}

const PoaForm: React.FC = () => {
  const [email, setEmail] = useState('')
  const [vin, setVin] = useState('')
  const [radioValue, setRadioValue] = useState<string>('company')
  const [selectCountry, setSelectCountry] = useState<string>('')
  const [selectedLanguage, setSelectedLanguage] = useState<string>('')
  const [shipmentType, setShipmentType] = useState<string>('automobile')
  const [loading, setLoading] = useState(false)
  const [templateList, setTemplateList] = useState<any>([])
  const { enqueueSnackbar } = useSnackbar()
  const customTemplateList = [
    {
      name: `vehicle-poa-${selectedLanguage}`,
      selected: false,
    },
    {
      name: `general-poa-${selectedLanguage}`,
      selected: false,
    },
    {
      name: `fiscal-poa-${selectedLanguage}`,
      selected: false,
    },
    {
      name: `photo-id-${selectedLanguage}`,
      selected: true,
    },
  ]

  useEffect(() => {
    if (selectCountry && selectedLanguage && radioValue && shipmentType) {
      getTemplateIds()
    }
  }, [selectCountry, selectedLanguage, radioValue, shipmentType])

  const getTemplateIds = () => {
    let templateIds: Iterable<any> | null | undefined = []
    if (shipmentType === 'package') {
      if (radioValue === 'private') {
        templateIds = [`general-poa-${selectedLanguage}`, `photo-id-${selectedLanguage}`]
      } else if (radioValue === 'company') {
        if (selectCountry === 'DE') {
          templateIds = [`general-poa-${selectedLanguage}`, `photo-id-${selectedLanguage}`]
        } else {
          templateIds = [`general-poa-${selectedLanguage}`, `fiscal-poa-${selectedLanguage}`, `photo-id-${selectedLanguage}`]
        }
      } else {
        templateIds = []
      }
    } else {
      if (radioValue === 'private') {
        templateIds = [`vehicle-poa-${selectedLanguage}`, `photo-id-${selectedLanguage}`]
      } else if (radioValue === 'company') {
        if (selectCountry === 'DE') {
          templateIds = [`vehicle-poa-${selectedLanguage}`, `photo-id-${selectedLanguage}`]
        } else {
          templateIds = [`vehicle-poa-${selectedLanguage}`, `fiscal-poa-${selectedLanguage}`, `photo-id-${selectedLanguage}`]
        }
      } else {
        templateIds = []
      }
    }

    const generatableTemplateNames = new Set(templateIds)
    const templates = [
      ...Array.from(templateIds).map((doc) => ({ name: doc, selected: true })),
      ...customTemplateList
        .filter((doc: { name: string }) => !generatableTemplateNames.has(doc.name.replace(/-\w{2}$/, `-${selectedLanguage}`)))
        .map((doc: { name: string; selected: boolean }) => ({
          ...doc,
          name: `${doc.name.replace(/-\w{2}$/, '')}-${selectedLanguage}`,
          selected: false,
        })),
    ]
    setTemplateList(templates)
  }

  const getSelectedTemplateNames = () => {
    const selectedTemplates = templateList.filter((template: { selected: boolean }) => template.selected)
    const photoIdTemplates = selectedTemplates.filter((template: { name: string }) => template.name.startsWith('photo-id'))
    const otherTemplates = selectedTemplates.filter((template: { name: string }) => !template.name.startsWith('photo-id'))
    return [
      ...otherTemplates.map((template: { name: string }) => template.name),
      ...photoIdTemplates.map((template: { name: string }) => template.name),
    ]
  }

  const handleSubmit = (e: FormEvent) => {
    setLoading(true)
    e.preventDefault()
    const selectedTemplateNames = getSelectedTemplateNames()
    const apiCall: ApiRequest<Response, Payload> = {
      method: 'POST',
      path: '/users/send_verify_email',
      data: {
        email,
        vin,
        customerType: radioValue,
        customerCountry: selectCountry,
        customerLanguage: selectedLanguage,
        templateList: selectedTemplateNames,
      },
      onSuccess: (data: Response) => {
        enqueueSnackbar(data.message, { variant: 'success' })
        setLoading(false)
      },
      onFail: (error) => {
        console.log(error)
        if (error && 'httpStatus' in error && error.httpStatus === 403) {
          enqueueSnackbar('Token already generated, please check your email or try after one minute', { variant: 'error' })
        } else {
          enqueueSnackbar(error?.message || 'An error occurred', { variant: 'error' })
        }
        setLoading(false)
      },
    }
    server(apiCall)
  }

  const handleVinChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    const vinValue = e.target.value as string
    setVin(vinValue)
    const vinPattern = /^[A-HJ-NPR-Z0-9]{17}$/
    if (vinPattern.test(vinValue)) {
      setShipmentType('automobile')
    } else {
      setShipmentType('')
    }
  }

  const handleTemplateSelect = (name: string) => {
    setTemplateList((prevState: { name: string; selected: boolean }[]) =>
      prevState.map((template) => (template.name === name ? { ...template, selected: !template.selected } : template))
    )
  }

  return (
    <PoaCard>
      <Grid container spacing={2} align-items='center' justify-content='center'>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth margin='normal'>
              <TextField
                size='small'
                variant='standard'
                value={email}
                label='Email'
                required
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(e.target.value as string)
                  if (e.target.value) {
                    // setEmailError(false)
                  } else {
                    // setEmailError(true)
                  }
                }}
              />
            </FormControl>
            <FormControl margin='normal'>
              <InlineRadioGroup aria-label='options' name='options' value={radioValue} onChange={(e) => setRadioValue(e.target.value)}>
                <FormControlLabel value='company' control={<Radio />} label='Company' />
                <FormControlLabel value='private' control={<Radio />} label='Private' />
              </InlineRadioGroup>
            </FormControl>
            <FormControl fullWidth margin='normal' required>
              <InputLabel>Country of Residence</InputLabel>
              <Select
                value={selectCountry}
                onChange={(e) => {
                  setSelectCountry(e.target.value as string)
                  // setCountryError(false)
                }}
              >
                <MenuItem key={'de'} value={'DE'}>
                  Germany
                </MenuItem>
                <MenuItem key={'others'} value={'others'}>
                  Other EU country
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin='normal' required>
              <InputLabel>POA Language</InputLabel>
              <Select value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.target.value as string)}>
                <MenuItem value={'en'}>English</MenuItem>
                <MenuItem value={'de'}>Deutsch</MenuItem>
                <MenuItem value={'pl'}>Polski</MenuItem>
                <MenuItem value={'ua'}>Ukrainian</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin='normal'>
              <TextField size='small' variant='standard' value={vin} label='Reference/VIN (Optional)' onChange={handleVinChange} />
            </FormControl>
            <FormControl fullWidth margin='normal' required>
              <InputLabel>Shipment Type</InputLabel>
              <Select value={shipmentType} onChange={(e) => setShipmentType(e.target.value as string)}>
                <MenuItem value={'automobile'}>Automobile</MenuItem>
                <MenuItem value={'package'}>General Cargo</MenuItem>
              </Select>
            </FormControl>
            {templateList.length > 0 && (
              <Container>
                <Typography variant={'subtitle1'}>The following documents will be generated</Typography>
                <List component='div' style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', width: '100%' }}>
                  {templateList.map((template: { name: string; selected: boolean }, index: number) => (
                    <ListItem key={index} alignItems='flex-start'>
                      <ListItemIcon>
                        <Checkbox
                          size='small'
                          checked={template.selected}
                          disabled={template.name.startsWith('photo-id')}
                          onChange={() => handleTemplateSelect(template.name)}
                          style={{ padding: '0 4px' }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={template.name} />
                    </ListItem>
                  ))}
                </List>
              </Container>
            )}
            <CTAButton type='submit'>{loading ? <LoadingDots /> : 'Submit'}</CTAButton>
          </form>
        </Grid>
      </Grid>
    </PoaCard>
  )
}

export default PoaForm
