import Button from '../../components/Button'
import React, { Suspense, useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import styled from 'styled-components'
import { ExternalShipmentInfoCard_shipment$key } from '../../__generated__/ExternalShipmentInfoCard_shipment.graphql'
import { useFragment, useLazyLoadQuery } from 'react-relay/hooks'
import { DialogContent, DialogTitle, Paper, Typography } from '@material-ui/core'
import KeyValueField from '../../components/KeyValueField'
import ExternalUploadDocumentsWizard from '../../components/ExternalUploadDocumentsWizard'
import OdysseyDialog from '../../components/OdysseyDialog'
import { FormFieldsContainer, StyledForm } from '../../components/ShipmentInfoCard'
import { Alert, Skeleton } from '@material-ui/lab'
import DialogFormActionBar from '../../components/Form/DialogFormActionBar'
import { ExternalShipmentInfoCardQuery } from '../../__generated__/ExternalShipmentInfoCardQuery.graphql'
import useNiceMutation from '../../mutations/useNiceMutation'
import { ExternalShipmentInfoCardMutation } from '../../__generated__/ExternalShipmentInfoCardMutation.graphql'
import NiceFormik from '../../components/Form/NiceFormik'
import InputField from '../../components/Form/InputField'

interface Props {
  shipment: ExternalShipmentInfoCard_shipment$key
}

const RootPaper = styled(Paper)`
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
`

const TitleContainer = styled.div`
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TitleAndContentContainer = styled.div`
  flex-grow: 1;
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
`

export const KeyFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;
`
export const CustomsInfoTextContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: pre-wrap;
`
const CustomsTextLabelContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    transform: translateY(-50%);
    background-color: ${(props) => (props.theme.palette.type === 'light' ? props.theme.palette.grey[300] : props.theme.palette.grey[700])};
  }
`
export const CustomsTextLabelTypography = styled(Typography)`
  padding: 4px 8px;
  padding-left: 0;
  text-align: center;
  color: ${(props) => (props.theme.palette.type === 'light' ? props.theme.palette.grey[700] : props.theme.palette.grey[300])};
  z-index: 1;
  background-color: ${(props) => props.theme.palette.background.paper};
`

export const CustomsTextLabel: React.FC = (props) => (
  <CustomsTextLabelContainer>
    <CustomsTextLabelTypography variant='caption'>{props.children}</CustomsTextLabelTypography>
  </CustomsTextLabelContainer>
)

const ExternalShipmentInfoCard: React.FC<Props> = ({ shipment: data }) => {
  const shipment = useFragment(fragment, data)
  const [openUploadWizard, setOpenUploadWizard] = React.useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const onEditClose = () => setEditDialogOpen(false)

  return (
    <RootPaper variant='outlined'>
      <TitleAndContentContainer>
        <TitleContainer>
          <Typography variant='h6'>Info</Typography>
          <Button onClick={() => setEditDialogOpen(true)}>Edit</Button>
        </TitleContainer>
        <Content>
          <KeyFieldsContainer>
            {shipment.infoExternalField &&
              shipment.infoExternalField.map((field) => <KeyValueField key={field.label} label={field.label} value={field.value} />)}
          </KeyFieldsContainer>
          <div>
            <CustomsTextLabel>Customer info</CustomsTextLabel>
            <CustomsInfoTextContainer>{shipment.customerInfoText || '--'}</CustomsInfoTextContainer>
          </div>
          {shipment.stage === 'document_collection' && (
            <>
              {shipment?.requiredDocuments && shipment?.requiredDocuments?.length > 0 && (
                <div>
                  <CustomsTextLabel>Document info text</CustomsTextLabel>
                  <CustomsInfoTextContainer>
                    <Typography color='error' variant='body2'>
                      Missing documents {shipment?.requiredDocuments?.join(', ')}
                    </Typography>
                  </CustomsInfoTextContainer>
                </div>
              )}
              <div>
                <Button onClick={() => setOpenUploadWizard(true)}>Request ePOA</Button>
              </div>
            </>
          )}
        </Content>
      </TitleAndContentContainer>
      <ExternalUploadDocumentsWizard
        // @ts-ignore
        shipment={data}
        slug={shipment.slug}
        open={openUploadWizard}
        onClose={() => setOpenUploadWizard(false)}
      />
      <OdysseyDialog open={editDialogOpen} fullWidth onClose={onEditClose} scroll='paper' style={{ maxWidth: '640px', margin: 'auto' }}>
        <DialogTitle>Editing Info</DialogTitle>
        <Suspense fallback={<EditForm.Skeleton onClose={onEditClose} />}>
          <EditForm slug={shipment.slug} onClose={onEditClose} />
        </Suspense>
      </OdysseyDialog>
    </RootPaper>
  )
}

interface EditFormProps {
  slug: string
  onClose: () => void
}

const EditForm: React.FC<EditFormProps> & { Skeleton: React.FC<{ onClose?: () => void }> } = ({ slug, onClose }) => {
  const data = useLazyLoadQuery<ExternalShipmentInfoCardQuery>(query, { slug: slug })
  const fields = data.infoExternalUpdateShipmentInfoForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))
  const [commit] = useNiceMutation<ExternalShipmentInfoCardMutation>(mutation)
  const [mutationError, setMutationError] = useState<null | string>(null)
  return (
    <NiceFormik
      initialValues={data.infoExternalUpdateShipmentInfoForm.initialValue || {}}
      onSubmit={(values, actions) => {
        commit({
          variables: {
            input: {
              ...values,
              slug: slug,
            },
          },
          onCompleted: (res, errors) => {
            actions.setSubmitting(false)
            if (errors) {
              setMutationError(errors.map((err) => err.message).join(', '))
            } else {
              onClose()
            }
          },
        })
      }}
      formSchema={fields}
    >
      {({ submitForm }) => (
        <StyledForm>
          {mutationError && <Alert severity='error'>{mutationError}</Alert>}
          <DialogContent dividers={true}>
            <FormFieldsContainer>
              <InputField of='expectedHandoverDate' field={fieldMaster['expectedHandoverDate']} />
            </FormFieldsContainer>
          </DialogContent>
          <DialogFormActionBar
            onCancel={() => onClose()}
            onSubmit={() => {
              submitForm()
            }}
            cancelCta='Cancel'
            saveCta='Save'
          />
        </StyledForm>
      )}
    </NiceFormik>
  )
}

EditForm.Skeleton = ({ onClose }) => (
  <>
    <DialogContent dividers={true}>
      <FormFieldsContainer>
        <Skeleton variant='rect' height='56px' />
        <Skeleton variant='rect' height='56px' />
        <Skeleton variant='rect' height='56px' />
        <Skeleton variant='rect' height='56px' />
      </FormFieldsContainer>
    </DialogContent>
    <DialogFormActionBar.Skeleton onCancel={onClose} />
  </>
)

const fragment = graphql`
  fragment ExternalShipmentInfoCard_shipment on ExternalShipment {
    slug
    customerInfoText
    stage
    requiredDocuments
    infoExternalField {
      label
      name
      value
    }
  }
`
const query = graphql`
  query ExternalShipmentInfoCardQuery($slug: String!) {
    infoExternalUpdateShipmentInfoForm(slug: $slug) {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
        }
      }
      initialValue {
        slug
        expectedHandoverDate
      }
    }
  }
`
const mutation = graphql`
  mutation ExternalShipmentInfoCardMutation($input: infoExternalUpdateShipmentInfoInput!) {
    infoExternalUpdateShipmentInfo(input: $input) {
      clientMutationId
    }
  }
`

export default ExternalShipmentInfoCard
