import { Button, Card, FormControl, Grid, TextField, Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import OdysseyLogo from '../assets/odyssey-logo.svg'
import { ApiRequest } from '../api/ApiTypes'
import server from '../api/server'
import { useLocation } from 'react-router'
import { parse } from 'qs'
import { useSnackbar } from 'notistack'
import LoadingDots from './LoadingDots'

const InfoCard = styled(Card)`
  padding: 16px 24px;
  background-color: rgb(255, 255, 255, 0.95);
`
const Odyssey = styled.img`
  width: 100%;
  margin-top: 16px;
`
const SuccessMessage = styled.p`
  color: green;
  font-weight: bold;
  margin-top: 16px;
`

const FailureMessage = styled.p`
  color: red;
  font-weight: bold;
  margin-top: 16px;
`

interface Payload {
  vin: string
  email: string
}

interface Response {
  data: {
    message: string
    email: string
    vin: string
    shipment_type: string
    esign_link: string
  }
}

const EmailVerify: React.FC = () => {
  const [verified, setVerified] = useState(false)
  const location = useLocation()
  const { token } = parse(location.search, { ignoreQueryPrefix: true }) as { token: string }
  const [loading, setLoading] = useState<boolean>(true)
  const [vin, setVin] = useState<string | null>(null)
  const [esignLink, setEsignLink] = useState<string | null>(null)
  const { enqueueSnackbar } = useSnackbar()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const apiCall: ApiRequest<Response, Payload> = {
    method: 'GET',
    path: `/users/verify_email?token=${token}`,
    onSuccess: ({ data }) => {
      setVerified(true)
      setVin(vin)
      setEsignLink(data.esign_link)
      if (data.esign_link) {
        window.open(data.esign_link, '_blank')
      }
      setLoading(false)
    },
    onFail: (error) => {
      if (error && 'httpStatus' in error && error.httpStatus === 403) {
        setErrorMessage('Email verification failed. The token has expired. Please generate a new verification link and try again.')
      } else if (error && 'httpStatus' in error && error.httpStatus === 401) {
        setErrorMessage('Email verification failed. The token is invalid. Please check the verification link and try again.')
      } else {
        setErrorMessage('Something went wrong. Please try again later.')
      }
      console.log(error)
      setVerified(false)
      setLoading(false)
    },
  }

  useEffect(() => {
    server(apiCall)
  }, [])

  const copyToClipboard = () => {
    if (esignLink) {
      navigator.clipboard.writeText(esignLink)
      enqueueSnackbar('e-Sign link copied to clipboard', { variant: 'success' })
    }
  }

  const VerificationInProgress: React.FC = () => {
    return (
      <Grid container direction='column' justify='center' alignItems='center' style={{ height: '20vh' }}>
        <Typography variant='body1' align='center' style={{ marginBottom: '26px' }}>
          Once email verification is complete, a new window will open with the e-POA signing tool. Please check for a popup or use the link
          provided. This process may take up to 30 seconds.
        </Typography>
        <LoadingDots variant='primary' />
      </Grid>
    )
  }

  return (
    <InfoCard>
      <Grid container spacing={2}>
        <Grid item lg={3} xs={2} />
        <Grid item lg={6} xs={8}>
          <Odyssey src={OdysseyLogo} />
        </Grid>
        <Grid item xs={12}>
          {loading ? (
            <VerificationInProgress />
          ) : verified ? (
            <SuccessMessage>
              Your email has been successfully verified! You can now proceed.
              {esignLink && (
                <FormControl fullWidth margin='normal'>
                  <TextField
                    label='e-Sign Link'
                    value={esignLink}
                    variant='outlined'
                    InputProps={{
                      endAdornment: (
                        <Button variant='contained' onClick={copyToClipboard}>
                          Copy
                        </Button>
                      ),
                    }}
                    disabled
                  />
                </FormControl>
              )}
            </SuccessMessage>
          ) : (
            <FailureMessage>{errorMessage}</FailureMessage>
          )}
        </Grid>
      </Grid>
    </InfoCard>
  )
}

export default EmailVerify
