import { Typography } from '@material-ui/core'
import React, { Suspense, useContext, useRef } from 'react'
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'
import Admin from '../pages/Admin/Admin'
import ApiLogs from '../pages/Admin/ApiLogs'
import Appbar from '../components/Appbar'
import ContactAdd from '../pages/Contact/ContactAdd'
import ContactShow from '../pages/Contact/ContactShow'
import ContainerAdd from '../pages/Container/ContainerAdd'
import ContainerShow from '../pages/Container/ContainerShow'
import ContainersTable from '../pages/v2/ContainersTable'
import Dashboard from '../pages/Dashboard/Dashboard'
import ItemList from '../pages/ItemList/ItemList'
import Profile from '../pages/Profile/Profile'
import SanctionJobLogs from '../pages/Admin/SanctionJobLogs'
import SanctionList from '../pages/Admin/SanctionList'
import SessionContext from '../SessionContext'
import Shipment from '../pages/Shipment'
import ShipmentAdd from '../pages/Shipment/ShipmentAdd'
import ShipmentShow from '../pages/Shipment/ShipmentShow'
import ShipmentsTable from '../pages/v2/ShipmentsTable'
import ShowLayout from './ShowLayout'
import styled from 'styled-components'
import TaxAssessments from '../pages/Admin/TaxAssessments'
import TicketShow from '../pages/Ticket/TicketShow'
import UnlinkedTaxAssessments from '../pages/Admin/UnlinkedTaxAssessments'
import UploadsTab from '../pages/Admin/UploadsTab'
import useWorkspace from '../hooks/useWorkspace'
import VesselAdd from '../pages/Vessel/VesselAdd'
import VesselShow from '../pages/Vessel/VesselShow'
import VoyageAdd from '../pages/Voyage/VoyageAdd'
import VoyageShow from '../pages/Voyage/VoyageShow'
import Workspace from '../pages/Workspace/Workspace'
import { media } from '../theme'
import { ErrorBoundaryWithBox } from '../utils/ErrorBoundary'
import VinScrapingFailureLogs from '../pages/Admin/VinScrapingFailureLogs'
import ContactList from '../pages/Contact/ContactList'

interface AppContentProps {
  subNavIsActive?: boolean
}

const AppContent = styled.div<AppContentProps>`
  width: 100%;
  height: ${(props) => (props.subNavIsActive ? `calc(100vh - 64px - 48px)` : `calc(100vh - 64px)`)};
  margin-top: ${(props) => (props.subNavIsActive ? `calc(64px + 48px)` : '64px')};
  overflow-y: auto;

  ${(props) => media.small`
    height: calc(100vh - 56px);
    margin-top: ${props.subNavIsActive ? `calc(56px + 48px)` : '56px'};
  `}
`

const ComingSoonText = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[500]};
  text-align: center;
  padding: 32px;
  font-weight: 700;
`

const ComingSoonBanner = () => <ComingSoonText variant='h5'>Coming Soon</ComingSoonText>

export interface NavItemT {
  hasNavigation?: boolean
  label: string
  path: string
  activePath?: RegExp
  subMenus?: NavItemT[]
}

const Internal: React.FC = () => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const [workspace, workspaceName] = useWorkspace()
  const { user } = useContext(SessionContext)
  const location = useLocation()
  const currentPath = location.pathname.split('/').slice(2).join('/')

  const isSuperAdmin = Boolean(user?.contact?.displayRoles.find((role) => role.value === 'super-admin'))
  const navItems: NavItemT[] = [
    {
      hasNavigation: true,
      label: 'Shipments',
      path: '/shipments',
      activePath: /^shipment/,
    },
    {
      hasNavigation: true,
      label: 'Containers',
      path: '/containers',
      activePath: /^container/,
      subMenus: [
        {
          hasNavigation: true,
          label: 'Voyages',
          path: '/voyages',
          activePath: /^voyage/,
        },
        {
          hasNavigation: true,
          label: 'Vessels',
          path: '/vessels',
          activePath: /^vessel/,
        },
      ],
    },
    {
      hasNavigation: true,
      label: 'Contacts',
      path: '/contacts/external',
      activePath: /^contact/,
    },
    {
      hasNavigation: true,
      label: 'Tickets',
      path: '/tickets',
      activePath: /^ticket/,
    },
    {
      hasNavigation: true,
      label: 'Messages',
      path: '/messages',
      activePath: /^messages/,
    },
    {
      label: 'More',
      hasNavigation: false,
      path: '',
      subMenus: [
        {
          hasNavigation: true,
          label: `${workspaceName} Settings`,
          path: '/workspace/home',
          activePath: /^workspace/,
        },
        {
          hasNavigation: true,
          label: 'Tax Assessments',
          path: '/tax-assessments/linked',
          activePath: /^tax-assessments/,
        },
      ],
    },
  ]

  if (isSuperAdmin) {
    navItems.push({
      hasNavigation: true,
      label: 'Admin',
      path: '/admin/workspaces',
      activePath: /^admin/,
    })
  }

  const subMenus = navItems
    .filter((navItem) => navItem.subMenus?.length)
    .map((navItem: NavItemT) => navItem.subMenus) as unknown as NavItemT[][]

  const activeNavLabel = subMenus.length
    ? subMenus
        .reduce((acc, item) => [...acc, ...item])
        .concat(navItems)
        .filter((item) => item.activePath?.test(currentPath))[0]?.label
    : navItems.filter((item) => item.activePath?.test(currentPath))[0]?.label

  const adminSubNavItems = [
    {
      hasNavigation: true,
      label: 'Users',
      path: '/admin/users',
      activePath: /^admin\/users/,
    },
    {
      hasNavigation: true,
      label: 'Images',
      path: '/admin/images',
      activePath: /^admin\/images/,
    },
    {
      hasNavigation: true,
      label: 'Files',
      path: '/admin/files',
      activePath: /^admin\/files/,
    },
    {
      hasNavigation: true,
      label: 'Imports',
      path: '/admin/imports',
      activePath: /^admin\/imports/,
    },
    {
      hasNavigation: true,
      label: 'API Logs',
      path: '/admin/api-logs',
      activePath: /^admin\/api-logs/,
    },
    {
      hasNavigation: true,
      label: 'Sanction Jobs',
      path: '/admin/sanction-logs',
      activePath: /^admin\/sanction-logs/,
    },
    {
      hasNavigation: true,
      label: 'Sanction List',
      path: '/admin/sanction-list',
      activePath: /^admin\/sanction-list/,
    },
    {
      hasNavigation: true,
      label: 'VIN logs',
      path: '/admin/vin-logs',
      activePath: /^admin\/vin-logs/,
    },
  ]

  const contactSubNavItems = [
    {
      hasNavigation: true,
      label: 'External Contacts',
      path: '/contacts/external',
      activePath: /^contacts\/external/,
    },
    {
      hasNavigation: true,
      label: 'Team Portagent',
      path: '/contacts/internal',
      activePath: /^contacts\/internal/,
    },
  ]
  const taxAssessmentSubNavItems = [
    {
      hasNavigation: true,
      label: 'Linked',
      path: '/tax-assessments/linked',
      activePath: /^tax-assessments\/linked/,
    },
    {
      hasNavigation: true,
      label: 'Unlinked',
      path: '/tax-assessments/unlinked',
      activePath: /^tax-assessments\/unlinked/,
    },
  ]
  const subNavItems: { [key: string]: NavItemT[] } = /^contacts\//.test(currentPath)
    ? {
        Admin: adminSubNavItems,
        Contacts: contactSubNavItems,
      }
    : {
        Admin: adminSubNavItems,
        'Tax Assessments': taxAssessmentSubNavItems,
      }

  const activeSubNavLabel = subNavItems[activeNavLabel]?.filter((item) => item.activePath?.test(currentPath))[0]?.label || ''
  const activeThirdNavLabel = taxAssessmentSubNavItems.filter((item) => item.activePath.test(currentPath))[0]?.label || ''

  return (
    <>
      <Appbar
        navItems={navItems}
        subNavItems={subNavItems[activeNavLabel]}
        activeNavLabel={activeNavLabel}
        activeSubNavLabel={activeSubNavLabel}
        activeThirdNavLabel={activeThirdNavLabel}
        // thirdNavItems={/^admin\/tax-assessments/.test(currentPath) ? taxAssessmentSubNavItems : []}
        workspace={workspace}
      />
      <AppContent ref={scrollRef} subNavIsActive={activeSubNavLabel !== ''}>
        <ErrorBoundaryWithBox>
          <Suspense fallback={<>Loading...</>}>
            <Routes>
              <Route path='/:workspace'>
                <Route path='/@:handle' element={<Dashboard />} />
                <Navigate to={`/${workspace}/@${user?.username}`} />
                <Route path='/search' element={<ItemList scrollRef={scrollRef} type='item' title='Search' />} />
                <Route path='/contacts' element={<Navigate to={`/${workspace}/contacts/external`} replace />} />
                <Route
                  path='/tickets'
                  element={
                    <ItemList
                      scrollRef={scrollRef}
                      type='tickets'
                      title='Tickets'
                      entity='Tickets'
                      description='Requests raised by Team Portagent or External Users'
                    />
                  }
                />
                <Route
                  path='/messages'
                  element={<ContactList scrollRef={scrollRef} type='messages' title='Messages' entity='Messages' description='Messages' />}
                />
                <Route
                  path='/contacts/external'
                  element={
                    <ItemList
                      scrollRef={scrollRef}
                      type='external_contacts'
                      title='External Contacts'
                      entity='External Contact'
                      description='The people Portagent loves to work with!'
                      addItemUrl={`/${workspace}/contacts/external/add`}
                    />
                  }
                />
                <Route
                  path='/contacts/internal'
                  element={
                    <ItemList
                      scrollRef={scrollRef}
                      type='internal_contacts'
                      title='Team Members'
                      entity='Team Member'
                      description='Our awesome Portagent team'
                      addItemUrl={`/${workspace}/contacts/internal/add`}
                    />
                  }
                />
                <Route
                  path='/contacts/internal/add'
                  element={
                    <Suspense fallback={<ContactAdd.Skeleton />}>
                      <ContactAdd type='internal' title='New Team Member' />
                    </Suspense>
                  }
                />
                <Route
                  path='/contacts/external/add'
                  element={
                    <Suspense fallback={<ContactAdd.Skeleton />}>
                      <ContactAdd type='external' title='New Contact' />
                    </Suspense>
                  }
                />
                <Route path='/contact/:slug'>
                  <Route path='/:tab' element={<ContactShow />} />
                  <Route path='' element={<Navigate to='home' replace={true} />} />
                </Route>

                <Route path='/ticket/:slug/:tab' element={<TicketShow />} />

                <Route
                  path='/vessels'
                  element={
                    <ItemList scrollRef={scrollRef} type='vessels' title='Vessels' entity='Vessel' addItemUrl={`/${workspace}/vessels/add`} />
                  }
                />
                <Route
                  path='/vessels/add'
                  element={
                    <Suspense fallback={<VesselAdd.Skeleton />}>
                      <VesselAdd />
                    </Suspense>
                  }
                />
                <Route path='/vessel/:slug'>
                  <Route path='/:tab' element={<VesselShow />} />
                  <Route path='' element={<Navigate to='home' replace={true} />} />
                </Route>

                <Route
                  path='/voyages'
                  element={
                    <ItemList scrollRef={scrollRef} type='voyages' title='Voyages' entity='Voyage' addItemUrl={`/${workspace}/voyages/add`} />
                  }
                />
                <Route
                  path='/voyages/add'
                  element={
                    <Suspense fallback={<VoyageAdd.Skeleton />}>
                      <VoyageAdd />
                    </Suspense>
                  }
                />
                <Route path='/voyage/:slug/'>
                  <Route path='/:tab' element={<VoyageShow />} />
                  <Route path='' element={<Navigate to='home' replace={true} />} />
                </Route>

                <Route
                  path='/vessels'
                  element={
                    <ItemList scrollRef={scrollRef} type='vessels' title='Vessels' entity='Vessel' addItemUrl={`/${workspace}/vessels/add`} />
                  }
                />
                <Route
                  path='/vessels/add'
                  element={
                    <Suspense fallback={<VesselAdd.Skeleton />}>
                      <VesselAdd />
                    </Suspense>
                  }
                />
                <Route path='/vessel/:slug'>
                  <Route path='/:tab' element={<VesselShow />} />
                  <Route path='' element={<Navigate to='home' replace={true} />} />
                </Route>

                <Route
                  path='/voyages'
                  element={
                    <ItemList scrollRef={scrollRef} type='voyages' title='Voyages' entity='Voyage' addItemUrl={`/${workspace}/voyages/add`} />
                  }
                />
                <Route
                  path='/voyages/add'
                  element={
                    <Suspense fallback={<VoyageAdd.Skeleton />}>
                      <VoyageAdd />
                    </Suspense>
                  }
                />
                <Route path='/voyage/:slug/'>
                  <Route path='/:tab' element={<VoyageShow />} />
                  <Route path='' element={<Navigate to='home' replace={true} />} />
                </Route>

                <Route
                  path='/vessels'
                  element={
                    <ItemList scrollRef={scrollRef} type='vessels' title='Vessels' entity='Vessel' addItemUrl={`/${workspace}/vessels/add`} />
                  }
                />
                <Route
                  path='/vessels/add'
                  element={
                    <Suspense fallback={<VesselAdd.Skeleton />}>
                      <VesselAdd />
                    </Suspense>
                  }
                />
                <Route path='/vessel/:slug'>
                  <Route path='/:tab' element={<VesselShow />} />
                  <Route path='' element={<Navigate to='home' replace={true} />} />
                </Route>

                <Route
                  path='/voyages'
                  element={
                    <ItemList scrollRef={scrollRef} type='voyages' title='Voyages' entity='Voyage' addItemUrl={`/${workspace}/voyages/add`} />
                  }
                />
                <Route
                  path='/voyages/add'
                  element={
                    <Suspense fallback={<VoyageAdd.Skeleton />}>
                      <VoyageAdd />
                    </Suspense>
                  }
                />
                <Route path='/voyage/:slug/'>
                  <Route path='/:tab' element={<VoyageShow />} />
                  <Route path='' element={<Navigate to='home' replace={true} />} />
                </Route>

                <Route
                  path='/containers'
                  element={
                    <ItemList
                      scrollRef={scrollRef}
                      type='containers'
                      title='Containers'
                      entity='Container'
                      addItemUrl={`/${workspace}/containers/add`}
                    />
                  }
                />

                <Route path='/containers/v2' element={<ContainersTable />} />
                <Route
                  path='/containers/add'
                  element={
                    <Suspense fallback={<ContainerAdd.Skeleton />}>
                      <ContainerAdd />
                    </Suspense>
                  }
                />
                <Route path='/container/:slug'>
                  <Route path='/:tab' element={<ContainerShow />} />
                  <Route path='' element={<Navigate to='home' replace={true} />} />
                </Route>

                <Route
                  path='/shipments'
                  element={
                    <ItemList
                      scrollRef={scrollRef}
                      type='shipments'
                      title='Shipments'
                      entity='Shipment'
                      addItemUrl={`/${workspace}/shipments/add`}
                    />
                  }
                />
                <Route path='/shipments/v2' element={<ShipmentsTable />} />
                <Route
                  path='/shipments/add'
                  element={
                    <Suspense fallback={<ShipmentAdd.Skeleton />}>
                      <ShipmentAdd />
                    </Suspense>
                  }
                />
                <Route path='/shipment/:slug'>
                  <Route path='/:tab' element={<ShipmentShow />} />
                  <Route path='' element={<Navigate to='home' replace={true} />} />
                </Route>

                <Route path='/test-shipment' element={<Shipment />} />

                <Route path='/profile' element={<Profile />} />
                <Route path='/workspace/:tab' element={<Workspace />} />
                <Route
                  path='/tax-assessments/linked'
                  element={
                    <Suspense fallback={<TaxAssessments.Skeleton />}>
                      <TaxAssessments />
                    </Suspense>
                  }
                />
                <Route
                  path='/tax-assessments/unlinked'
                  element={
                    <Suspense fallback={<UnlinkedTaxAssessments.Skeleton />}>
                      <UnlinkedTaxAssessments />
                    </Suspense>
                  }
                />
                <Route path='/admin' element={<Admin />}>
                  <Route path='users' element={<ComingSoonBanner />} />
                  <Route path='images' element={<ComingSoonBanner />} />
                  <Route path='files' element={<ComingSoonBanner />} />
                  <Route path='api-logs' element={<ApiLogs />} />
                  <Route path='sanction-logs' element={<SanctionJobLogs />} />
                  <Route path='sanction-list' element={<SanctionList />} />
                  <Route path='vin-logs' element={<VinScrapingFailureLogs />} />
                  <Route path='tax-assessments' element={<Navigate to={`/${workspace}/admin/tax-assessments/linked`} replace />} />
                  <Route
                    path='imports'
                    element={
                      <>
                        <ComingSoonBanner />
                        <UploadsTab />
                      </>
                    }
                  />
                </Route>

                <Route path='/show-layout' element={<ShowLayout />} />
              </Route>
            </Routes>
            <Outlet />
          </Suspense>
        </ErrorBoundaryWithBox>
      </AppContent>
    </>
  )
}

export default Internal
