import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useFragment } from 'react-relay/hooks'
import Badge from '../../components/Badge'
import ItemImage from '../../components/ItemImage'
import KeyField from '../../components/KeyField'
import SemanticLine from '../../components/SemanticLine'
import StickyNote from '../../components/StickyNote'
import TextHighlighter, { HighlightT } from '../../components/TextHighlighter'
import { isLarge, isSmall } from '../../theme'
import { ViewMode } from '../../viewTypes'
import { ExternalListPageItem_listItem$key } from '../../__generated__/ExternalListPageItem_listItem.graphql'
import ArchivedBanner from '../../components/ArchivedBanner'
import {
  Content,
  FlexContainer,
  KeyFieldsContainer,
  Root,
  RootContainer,
  Stages,
  Step,
  TitleContainer,
  TitleLink,
  TitleRow,
  TitleText,
  Topbar,
  UnstyledLink,
} from '../../components/ListPageItem'

interface Props {
  listItem: ExternalListPageItem_listItem$key
  viewMode: ViewMode
  highlights?: HighlightT[]
}

const ExternalListPageItem: React.FC<Props> = ({ listItem, viewMode, highlights }) => {
  const item = useFragment(listItemFragment, listItem)
  const keyFields = viewMode === 'grid' ? item.keyFields?.filter((field) => field.key !== 'Documents') : item.keyFields

  return (
    <Root style={viewMode === 'grid' ? { margin: '10px', width: '380px' } : {}}>
      <ArchivedBanner isArchived={item.isArchived} variant='small' />
      <Topbar>
        {item.stages && item.stages.length > 0 && (
          <Stages>
            {item.stages.map((stage, idx) => (
              <Step key={stage.key} status={stage.status}>
                {stage.status === 'completed' || stage.status === 'incomplete' ? idx + 1 : stage.label}
              </Step>
            ))}
          </Stages>
        )}
      </Topbar>
      <RootContainer view={viewMode} isSmall={isSmall()}>
        {item.image && viewMode !== 'grid' && (
          <UnstyledLink to={`${item.path}/home` || '#'}>
            <ItemImage data={item.image} variant='small' />
          </UnstyledLink>
        )}
        <Content>
          <FlexContainer>
            {item.image && viewMode === 'grid' && (
              <UnstyledLink to={`${item.path}/home` || '#'}>
                <ItemImage variant='small' data={item.image} />
              </UnstyledLink>
            )}
            <TitleRow view={viewMode} title={item.title}>
              <TitleContainer view={viewMode}>
                <TitleLink to={`${item.path}/home` || '#'}>
                  <TitleText variant='subtitle1' view={viewMode} color='textPrimary'>
                    <TextHighlighter highlights={highlights} textToHighlight={item.title} />
                  </TitleText>
                  {item.lines?.map((line) => (
                    <SemanticLine key={line.key} data={line} highlights={highlights} />
                  ))}
                </TitleLink>
                {item.badges?.map((badge) => (
                  <span key={badge.key} style={{ marginTop: '3px' }}>
                    <Badge key={badge.key} data={badge} />
                  </span>
                ))}
                &nbsp;&nbsp;
                {item.stickyNoteContentByExternal && isLarge() && viewMode !== 'grid' ? (
                  <StickyNote note={item.stickyNoteContentByExternal} type='external' />
                ) : (
                  <></>
                )}
              </TitleContainer>
            </TitleRow>
          </FlexContainer>
          {keyFields && (
            <KeyFieldsContainer view={viewMode}>
              {keyFields.map((field) => (
                <KeyField key={field.key} data={field} highlights={highlights} />
              ))}
            </KeyFieldsContainer>
          )}
        </Content>
      </RootContainer>
    </Root>
  )
}

const listItemFragment = graphql`
  fragment ExternalListPageItem_listItem on ExternalListItem {
    slug
    title
    isArchived
    image {
      ...ItemImage_data
    }
    path
    stages {
      key
      status
      label
      value
    }
    badges {
      key
      ...Badge_data
    }
    lines {
      key
      ...SemanticLine_data
    }
    keyFields {
      key
      ...KeyField_data
    }
    stickyNoteContentByExternal
  }
`

export default ExternalListPageItem
