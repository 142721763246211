/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type MessageListQueryVariables = {
    slug: string;
    page?: number | null;
    perPage?: number | null;
};
export type MessageListQueryResponse = {
    readonly messageList: {
        readonly messages: ReadonlyArray<{
            readonly slug: string;
            readonly subject: string | null;
            readonly body: string;
            readonly sentAt: string;
            readonly sentAtDisplay: string;
            readonly senderSlug: string | null;
            readonly recipientSlugs: ReadonlyArray<string> | null;
            readonly to: ReadonlyArray<string>;
            readonly from: string;
            readonly entitySlug: string | null;
            readonly previewStatus: string;
            readonly deliveryStatus: string;
            readonly direction: string;
        }>;
        readonly shipments: ReadonlyArray<{
            readonly slug: string;
            readonly title: string | null;
            readonly vinLine: string | null;
        }>;
        readonly paginationMeta: {
            readonly hasNextPage: boolean;
            readonly hasPrevPage: boolean;
            readonly page: number;
            readonly perPage: number;
            readonly totalPages: number;
        } | null;
        readonly totalCount: number | null;
    };
};
export type MessageListQuery = {
    readonly response: MessageListQueryResponse;
    readonly variables: MessageListQueryVariables;
};



/*
query MessageListQuery(
  $slug: String!
  $page: Int
  $perPage: Int
) {
  messageList(slug: $slug, page: $page, perPage: $perPage) {
    messages {
      slug
      subject
      body
      sentAt
      sentAtDisplay
      senderSlug
      recipientSlugs
      to
      from
      entitySlug
      previewStatus
      deliveryStatus
      direction
    }
    shipments {
      slug
      title
      vinLine
    }
    paginationMeta {
      hasNextPage
      hasPrevPage
      page
      perPage
      totalPages
    }
    totalCount
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "perPage"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "page",
        "variableName": "page"
      },
      {
        "kind": "Variable",
        "name": "perPage",
        "variableName": "perPage"
      },
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "slug"
      }
    ],
    "concreteType": "MessageGroup",
    "kind": "LinkedField",
    "name": "messageList",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Message",
        "kind": "LinkedField",
        "name": "messages",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subject",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "body",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sentAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sentAtDisplay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "senderSlug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "recipientSlugs",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "to",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "from",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "entitySlug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "previewStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deliveryStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "direction",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ShipmentDetail",
        "kind": "LinkedField",
        "name": "shipments",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "vinLine",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Paginate",
        "kind": "LinkedField",
        "name": "paginationMeta",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasNextPage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasPrevPage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "page",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "perPage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalPages",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MessageListQuery",
    "selections": (v4/*: any*/),
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "MessageListQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "8d27b35b783cc81febb1f113400cd2fe",
    "id": null,
    "metadata": {},
    "name": "MessageListQuery",
    "operationKind": "query",
    "text": "query MessageListQuery(\n  $slug: String!\n  $page: Int\n  $perPage: Int\n) {\n  messageList(slug: $slug, page: $page, perPage: $perPage) {\n    messages {\n      slug\n      subject\n      body\n      sentAt\n      sentAtDisplay\n      senderSlug\n      recipientSlugs\n      to\n      from\n      entitySlug\n      previewStatus\n      deliveryStatus\n      direction\n    }\n    shipments {\n      slug\n      title\n      vinLine\n    }\n    paginationMeta {\n      hasNextPage\n      hasPrevPage\n      page\n      perPage\n      totalPages\n    }\n    totalCount\n  }\n}\n"
  }
};
})();
(node as any).hash = 'fb6095480d9fce4ef9b84aca12389057';
export default node;
