import React, { memo, useContext, useEffect, useRef, useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { MessageListQuery } from '../../__generated__/MessageListQuery.graphql'
import styled from 'styled-components'
import moment from 'moment'
import {
  Typography,
  TextField,
  Button,
  Paper,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import useNiceMutation from '../../mutations/useNiceMutation'
import { MessageListSendMessageMutation } from '../../__generated__/MessageListSendMessageMutation.graphql'
import { MessageListAttachShipmentMutation } from '../../__generated__/MessageListAttachShipmentMutation.graphql'
import { MessageListMarkReadMutation } from '../../__generated__/MessageListMarkReadMutation.graphql'
import { useSnackbar } from 'notistack'
import { useDateTimeDisplay } from '../../hooks/timeZoneDisplay'
import useLazyLoadQueryWithSubscription from '../../hooks/useLazyLoadQueryWithSubscription'
import SessionContext from '../../SessionContext'
interface MessageListProps {
  selectedUser: {
    number: number
    name: string
    username: string
    roles: string[]
    unreadMessageCount: number
  } | null
  isFirstLoad: boolean
  setIsFirstLoad: React.Dispatch<React.SetStateAction<boolean>>
}

const MessagesList = styled.div`
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: ${(props) => props.theme.palette.background.default};
`

const InputContainer = styled.div`
  padding: 16px;
  border-top: 1px solid ${(props) => props.theme.palette.grey[300]};
  display: flex;
  gap: 8px;
  background-color: ${(props) => props.theme.palette.background.paper};
`

const MessageBubble = styled(Paper)<{ isSentByCurrentUser: boolean }>`
  max-width: 70%;
  align-self: ${(props) => (props.isSentByCurrentUser ? 'flex-end' : 'flex-start')};
  background-color: ${(props) => (props.isSentByCurrentUser ? props.theme.palette.primary.light : props.theme.palette.grey[200])};
  color: ${(props) => (props.isSentByCurrentUser ? props.theme.palette.primary.contrastText : props.theme.palette.text.primary)};
  padding: 12px;
  border-radius: 12px;
  border-top-left-radius: ${(props) => (props.isSentByCurrentUser ? '12px' : '0')};
  border-top-right-radius: ${(props) => (props.isSentByCurrentUser ? '0' : '12px')};
  display: flex;
  align-items: center;
  gap: 8px;
`

const AvatarContainer = styled(Avatar)`
  align-self: flex-start;
`

const Metadata = styled(Typography)`
  font-size: 0.8rem;
  color: ${(props) => props.theme.palette.text.secondary};
  margin-top: 4px;
`

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
`

const FiltersAndButton = styled.div`
  display: flex;
  align-items: center;
`

const WarningContainer = styled.div`
  padding: 16px;
  background-color: #fff3cd;
  color: #856404;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  margin-bottom: 8px;
`

const MessageListComponent: React.FC<MessageListProps> = ({ selectedUser, isFirstLoad, setIsFirstLoad }) => {
  const { user } = useContext(SessionContext)
  const [params, setParams] = useState({
    page: 1,
    perPage: 200,
    slug: selectedUser?.username ?? '',
  })
  const queryData = useLazyLoadQueryWithSubscription<MessageListQuery>(query, params, {
    subscriptionOptions: {
      entityType: 'contacts',
      entitySlug: user?.username || '',
    },
  })

  // console.log(queryData,"queryData")
  const [messageList, setMessageList] = useState(queryData.messageList)
  const [commitSendMessage, sendMessageIsInFlight] = useNiceMutation<MessageListSendMessageMutation>(sendMessageMutation)
  const [commitMarkRead] = useNiceMutation<MessageListMarkReadMutation>(markReadMutation)
  const [commitAttachShipment] = useNiceMutation<MessageListAttachShipmentMutation>(attachShipmentMutation)
  const [selectedContact, setSelectedContact] = useState<string | null>(null)
  const [isAssignMode, setIsAssignMode] = useState(false)
  const [assignModalOpen, setAssignModalOpen] = useState(false)
  const [newMessage, setNewMessage] = useState<string>('')

  const scrollRef: any = useRef({
    hasNextPage: false,
    hasPrevPage: false,
    page: 1,
    perPage: 200,
    totalPages: 1,
    isLoading: false,
  })

  useEffect(() => {
    if (!queryData?.messageList) return
    let newUpdatedData
    if (isFirstLoad) {
      newUpdatedData = { ...queryData.messageList }
      setMessageList(newUpdatedData)
    } else {
      const updatedMessageList = { ...messageList }
      newUpdatedData = {
        ...queryData.messageList,
        messages: [...queryData.messageList.messages, ...updatedMessageList?.messages],
        shipments: [...queryData.messageList.shipments, ...updatedMessageList?.shipments],
      }
      setMessageList(newUpdatedData)
    }
    scrollRef.current = {
      ...queryData.messageList?.paginationMeta,
      isLoading: false,
    }
    markMessagesAsRead(newUpdatedData)
    setSelectedContact(selectedUser?.number?.toString() || null)
  }, [queryData?.messageList, isFirstLoad])

  const { enqueueSnackbar } = useSnackbar()
  const [selectedMessages, setSelectedMessages] = useState<
    {
      slug: string | undefined
      body: string
      sentAt: string
      sentAtDisplay: string
      from: string
      previewStatus: string
      deliveryStatus: string
      senderSlug?: string
      direction: string
      entitySlug?: string | undefined
    }[]
  >([])
  const [checkedMessages, setCheckedMessages] = useState<string[]>([])
  const [shipments, setShipments] = useState<{ slug: string; title: string; vinLine: string }[]>([])
  const [selectedShipment, setSelectedShipment] = useState<string | null>(null)
  const [sessionActive, setSessionActive] = useState<boolean>(true)
  const [shipmentSearchQuery, setShipmentSearchQuery] = useState<string>('')
  const messagesEndRef = useRef<HTMLDivElement | null>(null)
  const messagesListRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (messagesEndRef.current && params?.page == 1) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [selectedMessages])

  useEffect(() => {
    if (messageList?.shipments) {
      setShipments(
        messageList?.shipments.map((shipment) => ({
          slug: shipment.slug,
          title: shipment.title || '',
          vinLine: shipment.vinLine || '',
        })) || []
      )
    }
  }, [messageList.shipments])

  useEffect(() => {
    if (selectedUser?.username) {
      setParams({
        page: 1,
        perPage: 200,
        slug: selectedUser.username,
      })
    }
  }, [selectedUser?.username])

  useEffect(() => {
    if (messageList.messages) {
      const messages =
        messageList?.messages.map((message: any) => ({
          slug: message.slug,
          body: message.body,
          sentAt: message.sentAt,
          sentAtDisplay: message.sentAtDisplay,
          from: message.from,
          direction: message.direction,
          previewStatus: message.previewStatus,
          deliveryStatus: message.deliveryStatus.toString(),
          senderSlug: message.senderSlug || undefined,
          entitySlug: message.entitySlug || undefined,
        })) || []
      setSelectedMessages(messages)
      checkSessionActive(messages)
    }
  }, [messageList.messages])

  const prevScrollHeightRef = useRef(0)

  useEffect(() => {
    if (messagesListRef.current && prevScrollHeightRef.current) {
      const messageListDiv = messagesListRef.current

      setTimeout(() => {
        const heightDiff = messageListDiv.scrollHeight - prevScrollHeightRef.current

        if (heightDiff > 0) {
          messageListDiv.scrollTop += heightDiff - 150
        }

        prevScrollHeightRef.current = messageListDiv.scrollHeight
      }, 100)
    }
  }, [params.page])

  useEffect(() => {
    const handleScroll = () => {
      if (!messagesListRef.current) return

      const messageListDiv = messagesListRef.current
      const scrollTop = messageListDiv.scrollTop
      const paginationData = { ...scrollRef?.current }

      if (scrollTop === 0 && paginationData?.hasNextPage && !paginationData?.isLoading) {
        scrollRef.current = { ...scrollRef?.current, isLoading: true }
        prevScrollHeightRef.current = messageListDiv.scrollHeight
        setIsFirstLoad(false)
        setParams((prev) => ({ ...prev, page: prev.page + 1 }))
      }
    }

    const messageListDiv = messagesListRef.current
    if (messageListDiv) {
      messageListDiv.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (messageListDiv) {
        messageListDiv.removeEventListener('scroll', handleScroll)
      }
    }
  }, [scrollRef.current?.isLoading]) // Re-run the effect when isLoading flag changes

  const sendMessage = () => {
    if (!sessionActive) {
      enqueueSnackbar('User must send a message to activate the session.', {
        variant: 'warning',
      })
      return
    }
    const updatedState = {
      to: selectedContact ?? '',
      body: newMessage,
    }

    commitSendMessage({
      variables: {
        input: updatedState,
      },
      onCompleted: (response, errors) => {
        if (errors) {
          enqueueSnackbar('User may have to reply before senging a message', {
            variant: 'error',
          })
          return
        }
        const newMessageDetails = {
          slug: response.infoDirectMessage?.slug,
          body: newMessage,
          sentAt: new Date().toISOString(),
          sentAtDisplay: moment().fromNow(),
          from: 'doc@portagent.eu',
          previewStatus: 'sent',
          deliveryStatus: 'sent',
          direction: '1',
        }

        setNewMessage('')
        enqueueSnackbar('Message sent successfully', { variant: 'success' })
        setSelectedMessages((prevSelectedMessages: any) => [...prevSelectedMessages, newMessageDetails])
      },
    })
  }

  const filteredShipments = shipments.filter((shipment) => {
    return (
      shipment.title.toLowerCase().includes(shipmentSearchQuery.toLowerCase()) ||
      shipment.vinLine.toLowerCase().includes(shipmentSearchQuery.toLowerCase()) ||
      shipment.slug.toLowerCase().includes(shipmentSearchQuery.toLowerCase())
    )
  })

  const checkSessionActive = (messages: any) => {
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1]
      // Extract ISO 8601 timestamp by removing "on " prefix
      const isoTimestamp = lastMessage.sentAt.replace(/^on\s/, '')
      const lastMessageDate = new Date(isoTimestamp) // Create Date object from isoTimestamp

      // Check if the date is valid
      if (isNaN(lastMessageDate.getTime())) {
        console.error('Invalid date format:', isoTimestamp)
        return
      }

      const lastMessageTime = lastMessageDate.getTime() // Get the timestamp in milliseconds
      const currentTime = Date.now() // current time in milliseconds

      // Compare the time difference in milliseconds
      const timeDifference = currentTime - lastMessageTime

      // Check if the message was sent within the last 24 hours
      const isActive = timeDifference <= 24 * 60 * 60 * 1000
      setSessionActive(isActive)
    }
  }

  const handleSaveAssignments = () => {
    if (checkedMessages.length > 0 && selectedShipment) {
      handleAssign()
    } else {
      enqueueSnackbar('Please select at least one message to assign.', {
        variant: 'warning',
      })
    }
  }

  const markMessagesAsRead = (data: any) => {
    const unreadMessages = data?.messages
      .filter((message: any) => message.previewStatus !== '2' && message.direction === '0')
      .map((message: any) => message.slug)
    if (unreadMessages && unreadMessages.length > 0) {
      commitMarkRead({
        variables: {
          input: {
            messageSlugs: unreadMessages,
            userSlug: selectedUser?.username ?? '',
          },
        },
      })
    }
  }

  const handleAssign = () => {
    commitAttachShipment({
      variables: {
        input: {
          messageSlugs: checkedMessages,
          shipmentSlug: selectedShipment || '',
        },
      },
      onCompleted: (response, errors) => {
        if (errors) {
          enqueueSnackbar('Failed to assign messages to shipment', {
            variant: 'error',
          })
        } else {
          enqueueSnackbar('Messages assigned to shipment successfully', {
            variant: 'success',
          })
          setSelectedMessages((prevSelectedMessages) =>
            prevSelectedMessages.map((message) =>
              message.slug && checkedMessages.includes(message.slug) ? { ...message, entitySlug: selectedShipment || undefined } : message
            )
          )
        }
      },
      onError: () => {
        enqueueSnackbar('An error occurred while assigning messages to shipment', { variant: 'error' })
      },
    })
    setAssignModalOpen(false)
    setIsAssignMode(false)
    setSelectedShipment(null)
    setCheckedMessages([])
  }

  const toggleShipmentSelection = (shipmentSlug: string) => {
    setSelectedShipment(shipmentSlug)
  }

  const toggleMessageSelection = (messageSlug: string) => {
    setCheckedMessages((prev) => (prev.includes(messageSlug) ? prev.filter((slug) => slug !== messageSlug) : [...prev, messageSlug]))
  }

  return (
    <>
      {/* Main Section */}

      {
        <>
          <FilterContainer>
            <Typography variant='h6'>All Messages</Typography>
            <FiltersAndButton>
              <Button
                variant='contained'
                color={isAssignMode ? 'secondary' : 'primary'}
                onClick={() => {
                  if (isAssignMode && selectedMessages.length > 0) {
                    setAssignModalOpen(true)
                  } else {
                    setIsAssignMode((prev) => !prev)
                  }
                }}
              >
                {isAssignMode ? (selectedMessages.length > 0 ? 'Assign' : 'Cancel') : 'Assign Message'}
              </Button>
              {isAssignMode && (
                <Button
                  onClick={() => {
                    setIsAssignMode(false)
                    setCheckedMessages([])
                  }}
                >
                  Cancel
                </Button>
              )}
            </FiltersAndButton>
          </FilterContainer>
          <MessagesList ref={messagesListRef}>
            {selectedMessages.map((message, index) => (
              <MessageBubble key={index} isSentByCurrentUser={message.direction === '1'}>
                {!isAssignMode && <AvatarContainer>{message.senderSlug?.charAt(0)?.toUpperCase()}</AvatarContainer>}
                {isAssignMode && (
                  <Checkbox
                    checked={message.slug ? checkedMessages.includes(message.slug) : false}
                    onChange={() => message.slug && toggleMessageSelection(message.slug)}
                  />
                )}
                <div>
                  <Typography>{message.body}</Typography>
                  <Metadata>
                    {useDateTimeDisplay(message.sentAt)}
                    {message.entitySlug && ` | Shipment: ${message.entitySlug}`}
                  </Metadata>
                </div>
              </MessageBubble>
            ))}
            <div ref={messagesEndRef} />
          </MessagesList>

          {/* Input Box */}
          {!sessionActive && (
            <WarningContainer>
              <Typography> The 24-hour session is inactive. Please use the Shipment Page to send a template messages.</Typography>
            </WarningContainer>
          )}
          <InputContainer>
            <TextField
              fullWidth
              variant='outlined'
              placeholder='Type a message...'
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
            />
            <Button variant='contained' color='primary' onClick={sendMessage} disabled={sendMessageIsInFlight}>
              Send
            </Button>
          </InputContainer>

          <Dialog
            open={assignModalOpen}
            onClose={() => setAssignModalOpen(false)}
            PaperProps={{
              style: {
                marginTop: '-100px',
              },
            }}
          >
            <DialogTitle>Assign Messages to Shipment</DialogTitle>
            <DialogContent>
              <TextField
                fullWidth
                variant='outlined'
                placeholder='Search shipments ...'
                value={shipmentSearchQuery}
                onChange={(e) => setShipmentSearchQuery(e.target.value)}
                style={{ marginBottom: '16px' }}
              />
              <List>
                {filteredShipments.map((shipment) => (
                  <ListItem key={shipment.slug}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={selectedShipment === shipment.slug} onChange={() => toggleShipmentSelection(shipment.slug)} />
                      }
                      label={
                        <>
                          <Typography>{shipment.title}</Typography>
                          <Metadata>{shipment.vinLine}</Metadata>
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setAssignModalOpen(false)} color='default'>
                Cancel
              </Button>
              <Button onClick={handleSaveAssignments} color='primary'>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </>
      }
    </>
  )
}

const query = graphql`
  query MessageListQuery($slug: String!, $page: Int, $perPage: Int) {
    messageList(slug: $slug, page: $page, perPage: $perPage) {
      messages {
        slug
        subject
        body
        sentAt
        sentAtDisplay
        senderSlug
        recipientSlugs
        to
        from
        entitySlug
        previewStatus
        deliveryStatus
        direction
      }
      shipments {
        slug
        title
        vinLine
      }
      paginationMeta {
        hasNextPage
        hasPrevPage
        page
        perPage
        totalPages
      }
    }
  }
`

const attachShipmentMutation = graphql`
  mutation MessageListAttachShipmentMutation($input: infoAttachMessageShipmentInput!) {
    infoAttachMessageShipment(input: $input) {
      clientMutationId
    }
  }
`

const markReadMutation = graphql`
  mutation MessageListMarkReadMutation($input: infoMarkMessagesReadInput!) {
    infoMarkMessagesRead(input: $input) {
      clientMutationId
    }
  }
`

const sendMessageMutation = graphql`
  mutation MessageListSendMessageMutation($input: infoDirectMessageInput!) {
    infoDirectMessage(input: $input) {
      clientMutationId
      slug
    }
  }
`
export default memo(MessageListComponent)
