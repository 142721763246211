import moment from 'moment'

export const useDateTimeDisplay = (date: string) => {
  if (date.includes('on')) {
    return 'on ' + moment.utc(date.split('on ')[1]).local().format('DD MMM YYYY, HH:mm')
  } else if (date.includes('in') || date.includes('ago')) {
    return date
  } else {
    return moment
      .utc(date, ['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ssZ', 'MM/DD/YYYY HH:mm:ssZ', 'DD/MM/YYYY HH:mm:ssZ'])
      .local()
      .format('DD MMM YYYY, HH:mm')
  }
}
