import { IconButton, MenuItem, Tooltip } from '@material-ui/core'
import { Archive, TableChart, Unarchive, ViewList } from '@material-ui/icons'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useState } from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay/hooks'
import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate'
import { isSmall } from '../../theme'
import { ViewMode } from '../../viewTypes'
import { ExternalItemListHeaderQuery, ExternalItemListHeaderQueryVariables } from '../../__generated__/ExternalItemListHeaderQuery.graphql'
import Button from '../../components/Button'
import WorkspacedLink from '../../components/WorkspacedLink'
import { Add } from '@material-ui/icons'
import AddTicketDialog from '../../components/AddTicketDialog'
import SearchableMultiSelectFilter from '../../components/SearchableMultiSelectFilter'
import { DateFilter, HeaderResult, ResultText, Root, Sort, SortContainer, StyledLink } from '../ItemList/ItemListHeader'
import { StyledIconButton } from '../../components/DataTable'
import pluralize from 'pluralize'

interface Props {
  variables: ExternalItemListHeaderQueryVariables
  viewMode: ViewMode
  setViewMode?: (mode: ViewMode) => void
  roles: []
}

const archivableEntityTypes = ['shipments', 'containers', 'item']
const ExternalItemListHeader: React.FC<Props> = ({ variables, viewMode, setViewMode, roles = [] }) => {
  const data = useLazyLoadQuery<ExternalItemListHeaderQuery>(query, variables)
  // @ts-ignore
  const { sortKeys, filters, totalCount, downloadToken } = useFragment(fragment, data.externalItemList)
  const wsNavigate = useWorkspaceNavigate()
  const [addTicketOpen, setTicketOpen] = useState(false)

  const handleSortToggle = (key: string) => wsNavigate.relative({ sortKey: key })
  const toggleArchivedVisibility = () =>
    wsNavigate.relative({
      includeArchived: variables.includeArchived ? 'false' : 'true',
    })
  const checkShipperRole = () => {
    return Array.isArray(roles) && roles.some((item) => item.value === 'shipper')
  }

  return (
    <Root>
      <AddTicketDialog open={addTicketOpen} onClose={() => setTicketOpen(false)} entityType={variables.type as string} />
      <ResultText variant='body1'>
        {!isSmall() && (
          <HeaderResult
            totalCount={totalCount}
            downloadToken={downloadToken}
            type={variables.type as string}
            entity={pluralize.singular(variables.type as string)}
          />
        )}
      </ResultText>
      <SortContainer>
        {isSmall() && (
          <HeaderResult
            totalCount={totalCount}
            downloadToken={downloadToken}
            type={variables.type as string}
            entity={pluralize.singular(variables.type as string)}
          />
        )}
        {isSmall() && variables.type && archivableEntityTypes.includes(variables.type) && (
          <StyledIconButton $active={variables.includeArchived as boolean} size='normal' onClick={toggleArchivedVisibility}>
            {!variables.includeArchived ? <Archive style={{ color: 'inherit' }} fontSize='large' /> : <Unarchive fontSize='large' />}
          </StyledIconButton>
        )}
        {isSmall() && variables.type && ['shipments'].includes(variables.type) && (
          <>
            <WorkspacedLink to={'/' + variables.type + '/v3'}>
              <Tooltip title={'Table View'} interactive>
                <IconButton>
                  <TableChart fontSize='large' />
                </IconButton>
              </Tooltip>
            </WorkspacedLink>
          </>
        )}

        {['containers', 'shipments', 'tickets'].includes(variables.type || '') && checkShipperRole() && (
          <StyledLink to={'#'} onClick={() => setTicketOpen(true)}>
            <Button
              variant='contained'
              style={{
                padding: isSmall() ? '1px 8px' : '6px 16px',
                minWidth: isSmall() ? '0px' : '64px',
              }}
            >
              <Add />
            </Button>
          </StyledLink>
        )}
        {!isSmall() && variables.type && archivableEntityTypes.includes(variables.type) && (
          <Button style={{ minWidth: 145 }} variant='outlined' onClick={toggleArchivedVisibility}>
            {variables.includeArchived ? 'Hide Archived' : 'Include Archived'}
          </Button>
        )}
        <Sort
          select
          size='small'
          variant='outlined'
          value={variables.sortKey}
          label='Sort By'
          onChange={(e) => handleSortToggle(e.target.value)}
        >
          {sortKeys.map((option: any) => (
            <MenuItem key={option.key} value={option.key}>
              {option.title}
            </MenuItem>
          ))}
        </Sort>
        {!isSmall() && viewMode !== 'mini-list' && (
          <>
            <IconButton color={viewMode === 'list' ? 'primary' : 'default'} onClick={() => setViewMode && setViewMode('list')}>
              <ViewList />
            </IconButton>
          </>
        )}
        {!isSmall() && variables.type && ['shipments'].includes(variables.type) && (
          <>
            <WorkspacedLink to={'/' + variables.type + '/v3'}>
              <Tooltip title={'Table View'} interactive>
                <IconButton>
                  <TableChart />
                </IconButton>
              </Tooltip>
            </WorkspacedLink>
          </>
        )}
      </SortContainer>
      {isSmall() && (
        <SortContainer>
          {filters.map((filter: { key: string; name: string; values: any }) => (
            <SearchableMultiSelectFilter key={filter.key} name={filter.name} data={filter.values} filterKey={filter.key} />
          ))}
          <DateFilter type={variables.type as string} />
        </SortContainer>
      )}
    </Root>
  )
}

const fragment = graphql`
  fragment ExternalItemListHeader_data on ExternalItemList {
    sortKeys {
      key
      title
    }
    filters {
      key
      name
      values {
        label
        displayLabel
        selected
        count
      }
    }
    totalCount
    downloadToken
  }
`

const query = graphql`
  query ExternalItemListHeaderQuery(
    $type: ExternalListItemTypeEnum
    $filters: [FilterInput!]
    $sortKey: String
    $page: Int
    $perPage: Int
    $searchQuery: String
    $voyageEtaStart: Date
    $voyageEtaEnd: Date
    $dischargeDateStart: Date
    $dischargeDateEnd: Date
    $includeArchived: Boolean
    $showFilters: Boolean
  ) {
    externalItemList(
      type: $type
      filters: $filters
      sortKey: $sortKey
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
      voyageEtaStart: $voyageEtaStart
      voyageEtaEnd: $voyageEtaEnd
      dischargeDateStart: $dischargeDateStart
      dischargeDateEnd: $dischargeDateEnd
      includeArchived: $includeArchived
      showFilters: $showFilters
    ) {
      ...ExternalItemListHeader_data
    }
  }
`

export default ExternalItemListHeader
