import React, { RefObject, Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import styled from 'styled-components'
import { Typography, TextField, Avatar, Badge } from '@material-ui/core'
import useLazyLoadQueryWithSubscription from '../../hooks/useLazyLoadQueryWithSubscription'
import SessionContext from '../../SessionContext'
import MessageList from '../Message/MessageList'
import { ContactListQuery } from '../../__generated__/ContactListQuery.graphql'

interface SelectedUsers {
  number: number
  name: string
  username: string
  roles: string[]
  unreadMessageCount: number
}
interface Props {
  type: string
  title: string
  description?: string
  entity?: string
  scrollRef: RefObject<HTMLDivElement>
}

const Layout = styled.div`
  display: flex;
  padding: 10px;
  height: calc(100vh - 64px);
`

const Sidebar = styled.div`
  width: 350px;
  height: 100%;
  overflow-y: auto;
  border-right: 1px solid ${(props) => props.theme.palette.grey[300]};
`

const MessagesContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Metadata = styled(Typography)`
  font-size: 0.8rem;
  color: ${(props) => props.theme.palette.text.secondary};
  margin-top: 4px;
`

const ContactItem = styled.div<{ isSelected: boolean }>`
  padding: 12px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => (props.isSelected ? '#e0e0e0' : 'transparent')};
`

const ContactDetails = styled(Typography)`
  display: flex;
  gap: 8px;
`

export const LinkAvatarContainer = styled.div`
  padding: 8px;
  height: 100%;
`

export const LinkAvatar = styled(Avatar)`
  width: 40px;
  height: 40px;
`

const RoleText = styled(Typography)`
  display: inline-block;
  font-style: oblique;
  margin-right: 4px;
`

const query = graphql`
  query ContactListQuery {
    contactList {
      contacts {
        number
        name
        username
        roles
        unreadMessageCount
      }
    }
  }
`

const ContactList: React.FC<Props> = () => {
  const { user } = useContext(SessionContext)
  const queryData = useLazyLoadQueryWithSubscription<ContactListQuery>(
    query,
    {},
    {
      subscriptionOptions: {
        entityType: 'contacts',
        entitySlug: user?.username || '',
      },
    }
  )
  const [contactList, setContactList] = useState<ContactListQuery['response']['contactList']['contacts']>(queryData?.contactList?.contacts)
  const [selectedContact, setSelectedContact] = useState<SelectedUsers | null>(null)
  const memorizedContact = useMemo(() => selectedContact, [selectedContact])
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  useEffect(() => {
    setContactList(queryData.contactList?.contacts)
  }, [queryData?.contactList?.contacts])

  const [contactSearchQuery, setContactSearchQuery] = useState<string>('')

  const handleContactClick = useCallback(
    (contact: any, i: number) => {
      const updatedContact = [...contactList]
      updatedContact[i] = { ...updatedContact[i], unreadMessageCount: 0 }
      setContactList(updatedContact)
      setIsFirstLoad(true)
      setSelectedContact((prev) => (prev?.username === contact.username ? prev : { ...contact }))
    },
    [contactList]
  )

  const filteredContacts = contactList?.filter((contact) => {
    return (
      contact.number.toLowerCase().includes(contactSearchQuery.toLowerCase()) ||
      contact.name.toLowerCase().includes(contactSearchQuery.toLowerCase()) ||
      contact.username.toLowerCase().includes(contactSearchQuery.toLowerCase())
    )
  })

  return (
    <Layout>
      <Sidebar>
        <Typography variant='h6' style={{ padding: '16px' }}>
          Contacts
        </Typography>
        <TextField
          fullWidth
          variant='outlined'
          placeholder='Search contacts...'
          value={contactSearchQuery}
          onChange={(e) => setContactSearchQuery(e.target.value)}
          style={{ margin: '16px 0' }}
        />
        {filteredContacts?.map((contact: any, i: number) => (
          <ContactItem
            key={contact.number}
            isSelected={contact.username === selectedContact?.username}
            onClick={() => handleContactClick(contact, i)}
          >
            <ContactDetails>
              <LinkAvatarContainer>
                <LinkAvatar alt={contact.name.charAt(0)} src={contact.avatar || undefined}>
                  {!contact.avatar && contact.name.charAt(0)}{' '}
                </LinkAvatar>
              </LinkAvatarContainer>
              <div>
                <Typography>{contact.name}</Typography>
                <Metadata>{contact.number}</Metadata>
                <Metadata>Username: {contact.username}</Metadata>
                {contact?.roles?.map((role: string) => (
                  <RoleText variant='subtitle2' key={role}>
                    {role}
                  </RoleText>
                ))}
              </div>
            </ContactDetails>
            {contact?.unreadMessageCount > 0 && <Badge badgeContent={contact.unreadMessageCount} color='primary' />}
          </ContactItem>
        ))}
      </Sidebar>

      <MessagesContainer>
        <Suspense fallback={<p>Loading...</p>}>
          {selectedContact?.username ? (
            <MessageList selectedUser={memorizedContact} isFirstLoad={isFirstLoad} setIsFirstLoad={setIsFirstLoad} />
          ) : (
            ''
          )}
        </Suspense>
      </MessagesContainer>
    </Layout>
  )
}

export default ContactList
