/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ListItemTypeEnum = "containers" | "external_contacts" | "internal_contacts" | "item" | "messages" | "shipments" | "tickets" | "vessels" | "voyages" | "%future added value";
export type SearchContactsQueryVariables = {
    type: ListItemTypeEnum;
    searchQuery?: string | null;
    perPage?: number | null;
    requiredPagination?: boolean | null;
};
export type SearchContactsQueryResponse = {
    readonly itemList: {
        readonly items: {
            readonly nodes: ReadonlyArray<{
                readonly slug: string;
                readonly title: string;
                readonly itemType: string;
                readonly contactRoles: ReadonlyArray<string> | null;
                readonly lines: ReadonlyArray<{
                    readonly " $fragmentRefs": FragmentRefs<"SemanticLine_data">;
                }> | null;
                readonly image: {
                    readonly " $fragmentRefs": FragmentRefs<"ItemImage_data">;
                } | null;
            } | null> | null;
        };
    };
};
export type SearchContactsQuery = {
    readonly response: SearchContactsQueryResponse;
    readonly variables: SearchContactsQueryVariables;
};



/*
query SearchContactsQuery(
  $type: ListItemTypeEnum!
  $searchQuery: String
  $perPage: Int
  $requiredPagination: Boolean
) {
  itemList(type: $type, searchQuery: $searchQuery, perPage: $perPage, requiredPagination: $requiredPagination) {
    items {
      nodes {
        slug
        title
        itemType
        contactRoles
        lines {
          ...SemanticLine_data
        }
        image {
          ...ItemImage_data
        }
        id
      }
    }
  }
}

fragment ItemImage_data on Image {
  url
  alt
  highlight
  shape
  overlay
  overlayIcon
}

fragment SemanticLine_data on SemanticLine {
  parts {
    key
    ...SemanticString_data
  }
}

fragment SemanticString_data on SemanticString {
  text
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "perPage"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "requiredPagination"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchQuery"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v4 = [
  {
    "kind": "Variable",
    "name": "perPage",
    "variableName": "perPage"
  },
  {
    "kind": "Variable",
    "name": "requiredPagination",
    "variableName": "requiredPagination"
  },
  {
    "kind": "Variable",
    "name": "searchQuery",
    "variableName": "searchQuery"
  },
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemType",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contactRoles",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchContactsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ItemList",
        "kind": "LinkedField",
        "name": "itemList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ListItemConnection",
            "kind": "LinkedField",
            "name": "items",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ListItem",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SemanticLine",
                    "kind": "LinkedField",
                    "name": "lines",
                    "plural": true,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "SemanticLine_data"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Image",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ItemImage_data"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "SearchContactsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ItemList",
        "kind": "LinkedField",
        "name": "itemList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ListItemConnection",
            "kind": "LinkedField",
            "name": "items",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ListItem",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SemanticLine",
                    "kind": "LinkedField",
                    "name": "lines",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SemanticString",
                        "kind": "LinkedField",
                        "name": "parts",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "key",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "text",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Image",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "alt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "highlight",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shape",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "overlay",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "overlayIcon",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "95a3a26490a4a7e6e02c190c499d8a90",
    "id": null,
    "metadata": {},
    "name": "SearchContactsQuery",
    "operationKind": "query",
    "text": "query SearchContactsQuery(\n  $type: ListItemTypeEnum!\n  $searchQuery: String\n  $perPage: Int\n  $requiredPagination: Boolean\n) {\n  itemList(type: $type, searchQuery: $searchQuery, perPage: $perPage, requiredPagination: $requiredPagination) {\n    items {\n      nodes {\n        slug\n        title\n        itemType\n        contactRoles\n        lines {\n          ...SemanticLine_data\n        }\n        image {\n          ...ItemImage_data\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment ItemImage_data on Image {\n  url\n  alt\n  highlight\n  shape\n  overlay\n  overlayIcon\n}\n\nfragment SemanticLine_data on SemanticLine {\n  parts {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticString_data on SemanticString {\n  text\n}\n"
  }
};
})();
(node as any).hash = '254c2c97ec8e4a0419c4c9ff16791846';
export default node;
