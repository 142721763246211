/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoMarkMessagesReadInput = {
    clientMutationId?: string | null;
    messageSlugs: Array<string>;
    userSlug: string;
};
export type MessageListMarkReadMutationVariables = {
    input: infoMarkMessagesReadInput;
};
export type MessageListMarkReadMutationResponse = {
    readonly infoMarkMessagesRead: {
        readonly clientMutationId: string | null;
    } | null;
};
export type MessageListMarkReadMutation = {
    readonly response: MessageListMarkReadMutationResponse;
    readonly variables: MessageListMarkReadMutationVariables;
};



/*
mutation MessageListMarkReadMutation(
  $input: infoMarkMessagesReadInput!
) {
  infoMarkMessagesRead(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoMarkMessagesReadPayload",
    "kind": "LinkedField",
    "name": "infoMarkMessagesRead",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MessageListMarkReadMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MessageListMarkReadMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4ae473459d9e1558909ac4ad3adfca8c",
    "id": null,
    "metadata": {},
    "name": "MessageListMarkReadMutation",
    "operationKind": "mutation",
    "text": "mutation MessageListMarkReadMutation(\n  $input: infoMarkMessagesReadInput!\n) {\n  infoMarkMessagesRead(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ebc13951c574a8dc0da57010cad11440';
export default node;
