/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ContactListQueryVariables = {};
export type ContactListQueryResponse = {
    readonly contactList: {
        readonly contacts: ReadonlyArray<{
            readonly number: string;
            readonly name: string;
            readonly username: string;
            readonly roles: ReadonlyArray<string> | null;
            readonly unreadMessageCount: number | null;
        }>;
    };
};
export type ContactListQuery = {
    readonly response: ContactListQueryResponse;
    readonly variables: ContactListQueryVariables;
};



/*
query ContactListQuery {
  contactList {
    contacts {
      number
      name
      username
      roles
      unreadMessageCount
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ContactGroup",
    "kind": "LinkedField",
    "name": "contactList",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MessageContact",
        "kind": "LinkedField",
        "name": "contacts",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "number",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unreadMessageCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ContactListQuery",
    "selections": (v0/*: any*/),
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ContactListQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "9c02862d2751a98027f3c3e5f06d5a4e",
    "id": null,
    "metadata": {},
    "name": "ContactListQuery",
    "operationKind": "query",
    "text": "query ContactListQuery {\n  contactList {\n    contacts {\n      number\n      name\n      username\n      roles\n      unreadMessageCount\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd7b01a23e81e332a9e8f83ba538bb59f';
export default node;
