import { graphql } from 'babel-plugin-relay/macro'
import { parse } from 'qs'
import React, { RefObject, Suspense, useEffect, useState } from 'react'
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay/hooks'
import { useLocation } from 'react-router'
import Hero from '../../components/Hero'
import ResponsiveGrid from '../../components/ResponsiveGrid'
import { isSmall } from '../../theme'
import { ExternalItemType, ViewMode } from '../../viewTypes'
import { ExternalItemListQuery, ExternalItemListQueryVariables } from '../../__generated__/ExternalItemListQuery.graphql'
import ExternalItemListContent from './ExternalItemListContent'
import ExternalItemListFilters from './ExternalItemListFilters'
import ExternalItemListHeader from './ExternalItemListHeader'
import { ExternalItemListFiltersQuery } from '../../__generated__/ExternalItemListFiltersQuery.graphql'
import { ExternalItemListPaginationQuery } from '../../__generated__/ExternalItemListPaginationQuery.graphql'
import { ExternalItemList_data$key } from '../../__generated__/ExternalItemList_data.graphql'
import { HighlightT } from '../../components/TextHighlighter'

interface Props {
  type?: ExternalItemType
  title: string
  description?: string
  entity?: string
  roles?: []
  scrollRef: RefObject<HTMLDivElement>
}

const FiltersWrapper = ({ variables, type }: any) => {
  const filtersData = useLazyLoadQuery<ExternalItemListFiltersQuery>(filtersQuery, variables)
  return <ExternalItemListFilters data={filtersData.externalItemList} listType={type} showDataFilter={true} />
}

const ContentWrapper = ({ variables, viewMode, scrollRef }: any) => {
  const perPage = Number.parseInt(variables.perPage as string) || 10

  const data = useLazyLoadQuery<ExternalItemListQuery>(query, variables)

  const {
    data: { externalItemList },
    loadNext,
    hasNext,
    isLoadingNext,
  } = usePaginationFragment<ExternalItemListPaginationQuery, ExternalItemList_data$key>(paginatedItemsFragment, data)

  const highlights: HighlightT[] =
    variables.searchQuery && variables.searchQuery.length >= 3 ? [{ type: 'searchTerm', value: variables.searchQuery }] : []

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.onscroll = () => {
        if (
          hasNext &&
          Math.ceil((scrollRef.current?.scrollTop || 0) + (scrollRef.current?.offsetHeight || 0)) >= (scrollRef.current?.scrollHeight || 0)
        ) {
          loadNext(perPage)
        }
      }
    }
  }, [hasNext, loadNext])

  return <ExternalItemListContent items={externalItemList.items} viewMode={viewMode} highlights={highlights} isLoadingNext={isLoadingNext} />
}

const ExternalItemList: React.FC<Props> = ({ type, scrollRef, roles = [] }) => {
  const location = useLocation()
  const {
    sortKey,
    perPage: perPageRaw,
    page: pageRaw,
    searchQuery,
    voyageEtaStart,
    voyageEtaEnd,
    dischargeDateStart,
    dischargeDateEnd,
    includeArchived,
    ...queryVariables
  } = parse(location.search, {
    ignoreQueryPrefix: true,
  })
  const filters = Object.entries(queryVariables).map((entry) => ({ key: entry[0], values: entry[1] as string[] }))
  const page = Number.parseInt(pageRaw as string) || 1
  const perPage = Number.parseInt(perPageRaw as string) || 10
  const [viewMode, setViewMode] = useState<ViewMode>(isSmall() ? 'grid' : 'list')
  const variables: ExternalItemListQueryVariables = {
    type,
    filters,
    searchQuery: `${searchQuery || ''}`,
    sortKey: `${sortKey || ''}`,
    page,
    perPage,
    voyageEtaStart: voyageEtaStart !== undefined ? voyageEtaStart.toString() : undefined,
    voyageEtaEnd: voyageEtaEnd !== undefined ? voyageEtaEnd.toString() : undefined,
    dischargeDateStart: dischargeDateStart !== undefined ? dischargeDateStart.toString() : undefined,
    dischargeDateEnd: dischargeDateEnd !== undefined ? dischargeDateEnd.toString() : undefined,
    includeArchived: includeArchived === 'true',
    showFilters: true,
    count: perPage,
  }

  const layout = viewMode === 'list' ? 'index-list-layout' : 'index-grid-layout'

  return (
    <>
      <Hero backgroundColor='transparent'>
        <Suspense key={type + 'header'} fallback={<>Loading...</>}>
          <ExternalItemListHeader variables={variables} viewMode={viewMode} setViewMode={setViewMode} roles={roles} />
        </Suspense>
      </Hero>
      <ResponsiveGrid
        type={layout}
        sidebar={
          isSmall()
            ? []
            : [
                <Suspense key={type + 'filters'} fallback={<>Loading...</>}>
                  <FiltersWrapper variables={variables} type={type} />
                </Suspense>,
              ]
        }
        content={[
          <Suspense key={type + '-item-list'} fallback={<>Loading...</>}>
            <ContentWrapper {...{ variables, scrollRef, viewMode }} />
          </Suspense>,
        ]}
      />
    </>
  )
}

const query = graphql`
  query ExternalItemListQuery(
    $searchQuery: String
    $type: ExternalListItemTypeEnum
    $filters: [FilterInput!]
    $includeArchived: Boolean
    $sortKey: String
    $page: Int
    $perPage: Int
    $voyageEtaStart: Date
    $voyageEtaEnd: Date
    $dischargeDateStart: Date
    $dischargeDateEnd: Date
    $count: Int
    $cursor: String
    $showFilters: Boolean
  ) {
    ...ExternalItemList_data
  }
`

const paginatedItemsFragment = graphql`
  fragment ExternalItemList_data on ApplicationQuery @refetchable(queryName: "ExternalItemListPaginationQuery") {
    externalItemList(
      searchQuery: $searchQuery
      type: $type
      filters: $filters
      includeArchived: $includeArchived
      sortKey: $sortKey
      page: $page
      perPage: $perPage
      voyageEtaStart: $voyageEtaStart
      voyageEtaEnd: $voyageEtaEnd
      dischargeDateStart: $dischargeDateStart
      dischargeDateEnd: $dischargeDateEnd
      showFilters: $showFilters
    ) {
      items(first: $count, after: $cursor) @connection(key: "ItemList_itemList_items") {
        edges {
          node {
            slug
            ...ExternalListPageItem_listItem
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`

const filtersQuery = graphql`
  query ExternalItemListFiltersQuery(
    $type: ExternalListItemTypeEnum
    $filters: [FilterInput!]
    $sortKey: String
    $page: Int
    $perPage: Int
    $searchQuery: String
    $voyageEtaStart: Date
    $voyageEtaEnd: Date
    $dischargeDateStart: Date
    $dischargeDateEnd: Date
  ) {
    externalItemList(
      type: $type
      filters: $filters
      sortKey: $sortKey
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
      voyageEtaStart: $voyageEtaStart
      voyageEtaEnd: $voyageEtaEnd
      dischargeDateStart: $dischargeDateStart
      dischargeDateEnd: $dischargeDateEnd
      showFilters: true
    ) {
      ...ExternalItemListFilters_data
    }
  }
`

export default ExternalItemList
