import React from 'react'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import { isSmall, media } from '../theme'
import LoginForm from '../components/LoginForm'
import PoaForm from '../components/PoaForm'
import PoaInformationalText from '../components/PoaInformationText'
import EmailVerify from '../components/EmailVerify'

interface Props {
  form?: string
}

const Root = styled.div`
  width: 100%;
  min-height: 100vh; /* Ensures it takes the full viewport height */
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 8px;
  grid-template-rows: auto;
  grid-template-areas: 'te te te te te te . lo lo lo lo lo';
  justify-items: center;
  align-items: start; /* Aligns form properly */
  padding: 16px;
  overflow-y: auto; /* Allows scrolling if needed */
`

const MobileRoot = styled.div`
  width: 100%;
  min-height: 100vh; /* Ensures full-page height */
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  justify-items: center;
  align-items: center; /* Centers content */
  padding: 16px;
  overflow-y: auto; /* Allows scrolling if needed */
`

const TextContainer = styled.div`
  grid-area: te;
`

const FormContainer = styled.div`
  grid-area: lo;
  max-width: 512px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LandingTitle = styled(Typography)`
  color: white;
  font-weight: bold;
  padding-left: 16px;
  border-left: 4px solid #00abe8;
  margin: 32px 0;

  ${media.mobile`
    font-size: 26px;
  `}
`

const LandingBody = styled(Typography)`
  color: white;
  display: block;
  font-size: 18px;
  font-weight: 400;
  margin: 32px 0;

  ${media.giant`
    width: 80%;
  `}

  ${media.desktop`
    width: 80%;
  `}

  ${media.mobile`
    font-size: 18px;
  `}
`

const FormRenderer: React.FC<Props> = ({ form }) => {
  switch (form) {
    case 'poa':
      return (
        <>
          <PoaInformationalText />
          <PoaForm />
        </>
      )
    case 'verify':
      return <EmailVerify />
    default:
      return <LoginForm />
  }
}

export const PoaLandingPage: React.FC<Props> = (props) => {
  return (
    <>
      {isSmall() ? (
        <MobileRoot>
          <FormContainer>
            <LandingTitle variant='h6'>Generate e-POA & Request Signature</LandingTitle>
            <FormRenderer form={props.form} />
          </FormContainer>
        </MobileRoot>
      ) : (
        <Root>
          <TextContainer>
            <LandingTitle variant='h4'>Generate e-POA & Request Signature</LandingTitle>
            <LandingBody variant='body1'>Fill out the form below to generate e-POA documents.</LandingBody>
            <LandingBody variant='body1'>
              After submitting, a verification email will be sent to the provided email address. Once verified, the same email address will
              receive a link to update the information and electronically sign the POA documents.
            </LandingBody>
          </TextContainer>
          <FormContainer>
            <FormRenderer form={props.form} />
          </FormContainer>
        </Root>
      )}
    </>
  )
}

const LandingPage: React.FC<Props> = (props) => {
  return (
    <>
      {isSmall() ? (
        <MobileRoot>
          <FormContainer>
            <LandingTitle variant='h6'>Full transparency in every step of the process</LandingTitle>
            <FormRenderer form={props.form} />
          </FormContainer>
        </MobileRoot>
      ) : (
        <Root>
          <TextContainer>
            <LandingTitle variant='h4'>Full transparency in every step of the process</LandingTitle>
            <LandingBody variant='body1'>
              Portagent performs customs clearance of vehicles from the USA, Canada, Japan, United Arab Emirates and South Korea
            </LandingBody>
            <LandingBody variant='body1'>
              Our <b>Odyssey</b> platform enables us to provide the highest level of competence and partnership in port logistics and customs
              clearance.
            </LandingBody>
          </TextContainer>
          <FormContainer>
            <FormRenderer form={props.form} />
          </FormContainer>
        </Root>
      )}
    </>
  )
}

export default LandingPage
