import { DialogContent, DialogTitle, Typography } from '@material-ui/core'
import React, { Suspense, useState } from 'react'
import { FormFieldsContainer, KeyFieldsContainer, RootPaper, StyledForm, TitleAndContentContainer } from '../../components/ShipmentInfoCard'
import styled from 'styled-components'
import { graphql } from 'babel-plugin-relay/macro'
import { VinDetailsCard_shipment$key } from '../../__generated__/VinDetailsCard_shipment.graphql'
import { useFragment, useLazyLoadQuery } from 'react-relay/hooks'
import KeyValueField from '../../components/KeyValueField'
import { VinDetailsCardEditDialogQuery } from '../../__generated__/VinDetailsCardEditDialogQuery.graphql'
import NiceFormik from '../../components/Form/NiceFormik'
import DialogFormActionBar from '../../components/Form/DialogFormActionBar'
import { Alert, Skeleton } from '@material-ui/lab'
import { VinDetailsCardEditDialogMutation } from '../../__generated__/VinDetailsCardEditDialogMutation.graphql'
import useNiceMutation from '../../mutations/useNiceMutation'
import OdysseyDialog from '../../components/OdysseyDialog'
import Button from '../../components/Button'
import InputField from '../../components/Form/InputField'

interface Props {
  shipment: VinDetailsCard_shipment$key
}

export const TitleContainer = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Content = styled.div`
  padding-left: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
`

const VinDetailsCard: React.FC<Props> = ({ shipment: data }) => {
  const shipment = useFragment(fragment, data)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const onEditClose = () => setEditDialogOpen(false)
  return (
    <>
      <RootPaper variant='outlined'>
        <TitleAndContentContainer>
          <TitleContainer>
            <Typography variant='h6'>Vin Details</Typography>
            <Button onClick={() => setEditDialogOpen(true)}>Edit</Button>
          </TitleContainer>
          <Content>
            <KeyFieldsContainer>
              {shipment.vinDetails.map((field) => (
                <KeyValueField key={field.label} label={field.label} value={field.value} />
              ))}
            </KeyFieldsContainer>
          </Content>
        </TitleAndContentContainer>
        <OdysseyDialog open={editDialogOpen} fullWidth onClose={onEditClose} scroll='paper' style={{ maxWidth: '640px', margin: 'auto' }}>
          <DialogTitle>Editing Vin Details</DialogTitle>
          <Suspense fallback={<EditForm.Skeleton onClose={onEditClose} />}>
            <EditForm shipmentSlug={shipment.slug} onClose={onEditClose} />
          </Suspense>
        </OdysseyDialog>
      </RootPaper>
    </>
  )
}
interface EditFormProps {
  shipmentSlug: string
  onClose: () => void
}

const EditForm: React.FC<EditFormProps> & { Skeleton: React.FC<{ onClose?: () => void }> } = ({ shipmentSlug, onClose }) => {
  const data = useLazyLoadQuery<VinDetailsCardEditDialogQuery>(query, { shipmentSlug: shipmentSlug })
  const fields = data.infoCreateOrUpdateVinDetailForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))
  const [mutationError, setMutationError] = useState<null | string>(null)
  const [commit] = useNiceMutation<VinDetailsCardEditDialogMutation>(mutation)

  return (
    <NiceFormik
      initialValues={data.infoCreateOrUpdateVinDetailForm.initialValue || { shipmentSlug: shipmentSlug }}
      onSubmit={(values, actions) => {
        commit({
          variables: {
            input: {
              ...values,
              shipmentSlug: shipmentSlug,
            },
          },
          onCompleted: (res, errors) => {
            actions.setSubmitting(false)
            if (errors) {
              setMutationError(errors.map((err) => err.message).join(', '))
            } else {
              onClose()
            }
          },
        })
      }}
      formSchema={fields}
    >
      {({ submitForm }) => (
        <StyledForm>
          {mutationError && <Alert severity='error'>{mutationError}</Alert>}
          <DialogContent dividers={true}>
            <FormFieldsContainer>
              <InputField of='year' field={fieldMaster['year']} />
              <InputField of='model' field={fieldMaster['model']} />
              <InputField of='body' field={fieldMaster['body']} />
              <InputField of='engine' field={fieldMaster['engine']} />
              <InputField of='country' field={fieldMaster['country']} />
              <InputField of='countryCode' field={fieldMaster['countryCode']} />
              <InputField of='engineCapacity' field={fieldMaster['capacity']} />
              <InputField of='fuelType' field={fieldMaster['fuelType']} />
            </FormFieldsContainer>
          </DialogContent>
          <DialogFormActionBar
            onCancel={() => onClose()}
            onSubmit={() => {
              submitForm()
            }}
            cancelCta='Cancel'
            saveCta='Save'
          />
        </StyledForm>
      )}
    </NiceFormik>
  )
}

EditForm.Skeleton = ({ onClose }) => (
  <>
    <DialogContent dividers={true}>
      <FormFieldsContainer>
        <Skeleton variant='rect' height='56px' />
        <Skeleton variant='rect' height='56px' />
        <Skeleton variant='rect' height='56px' />
        <Skeleton variant='rect' height='56px' />
        <Skeleton variant='rect' height='56px' />
        <Skeleton variant='rect' height='56px' />
      </FormFieldsContainer>
    </DialogContent>
    <DialogFormActionBar.Skeleton onCancel={onClose} />
  </>
)

const fragment = graphql`
  fragment VinDetailsCard_shipment on Shipment {
    slug
    vinDetails {
      label
      name
      value
    }
  }
`
const query = graphql`
  query VinDetailsCardEditDialogQuery($shipmentSlug: String!) {
    infoCreateOrUpdateVinDetailForm(shipmentSlug: $shipmentSlug) {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
        }
      }
      initialValue {
        shipmentSlug
        year
        model
        body
        engine
        country
        countryCode
        capacity
        fuelType
      }
    }
  }
`

const mutation = graphql`
  mutation VinDetailsCardEditDialogMutation($input: infoCreateOrUpdateVinDetailInput!) {
    infoCreateOrUpdateVinDetail(input: $input) {
      clientMutationId
    }
  }
`

export default VinDetailsCard
