/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MessageItem_message = {
    readonly slug: string;
    readonly subject: string | null;
    readonly from: string;
    readonly to: ReadonlyArray<string>;
    readonly body: string;
    readonly sentAt: string;
    readonly attachments: ReadonlyArray<{
        readonly name: string;
        readonly " $fragmentRefs": FragmentRefs<"SmallAttachment_attachment" | "Attachment_attachment">;
    }>;
    readonly " $refType": "MessageItem_message";
};
export type MessageItem_message$data = MessageItem_message;
export type MessageItem_message$key = {
    readonly " $data"?: MessageItem_message$data;
    readonly " $fragmentRefs": FragmentRefs<"MessageItem_message">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MessageItem_message",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subject",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "from",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "to",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sentAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Attachment",
      "kind": "LinkedField",
      "name": "attachments",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SmallAttachment_attachment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Attachment_attachment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Message",
  "abstractKey": null
};
(node as any).hash = '97e3e132e1434babf6678dff4691077d';
export default node;
